﻿.icon {
  $sz: 18;

  width: calculateRem($sz);
  height: calculateRem($sz);
  display: inline-block;
  margin-right: calculateRem(10);
  background: $b4;
  border-radius: 14px;
}

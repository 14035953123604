@import '../config';

.login {
  background: $b44;
  display: flex;
  position: relative;
  padding-bottom: 50px;
}

.sign-in-bg,
.sign-in__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    -20deg,
    #ffcc33 -10%,
    #009966 20%,
    #0099cc 70%,
    #ff3333 100%
  );
  transform: skewY(-7deg) translateY(-10%);
  transform-origin: 0;
  box-shadow: 0 30px 30px rgba(#000, 0.1);
}

.sign-in {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    filter: blur(10px);
    opacity: 0.5;
  }

  &__source {
    padding: 20px 0 40px;
    font-size: calculateRem(14);
    font-weight: 700;
    color: rgba(#000, 0.3);
    text-transform: uppercase;

    @include selectionStyles {
      background: transparent;
    }
  }

  &__title {
    margin-bottom: 40px;
    color: $w0;
    font-weight: 400;
    font-size: calculateRem(48);
    text-transform: lowercase;
    text-shadow: 0 2px 4px rgba(#000, 0.1);
    opacity: 0.9;

    @include selectionStyles {
      background: transparent;
    }
  }

  &__container {
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    z-index: 100;
    background: rgba(#fff, 0.85);
    border-radius: 6px;
    box-shadow: 0 10px 14px rgba(#000, 0.1), 0 0 0 1px rgba(#000, 0.1);
  }

  &__alt-option,
  &__welcome {
    text-align: center;
    font-weight: 700;
    @include selectionStyles {
      background: transparent;
    }
  }

  &__alt-option {
    font-size: 14px;
    color: rgba($blu2, 0.5);
    @include selectionStyles {
      background: transparent;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($blu2, 0.2);
      box-shadow: 0 10px 30px -20px rgba($blu2, 0.5);
    }
  }

  &__msft {
    padding: 10px 20px;
    align-self: center;
    font-size: calculateRem(14);
    font-weight: 700;
    background: #fff;
    border: 2px solid #333;
    @include selectionStyles {
      background: transparent;
    }
  }

  &__field-container {
    display: flex;

    & + & {
      margin-top: 10px;
    }
  }

  &__field-label {
    display: none;
  }

  &__field-input {
    padding: 10px;
    flex: 1;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(#000, 0.2), 0 2px 2px -1px rgba(#000, 0.025);
    transition: box-shadow 120ms ease;
    font-weight: 700;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 1px rgba(#000, 0.1),
        -12px 0 13px -7px rgba(#c678dd, 0.3), 0 0 5px 0 #69ade7,
        9px 0 18px -7px #c3e88d;
      transition: box-shadow 120ms ease;
    }
  }

  &__field-remember {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__remember-label {
    padding-left: 8px;
    font-size: calculateRem(14);
    @include selectionStyles {
      background: transparent;
    }
  }

  &__forgot-password {
    margin-left: auto;
    padding-left: 20px;
    font-size: calculateRem(14);
    font-weight: 700;
    color: $blu2;
    @include selectionStyles {
      background: transparent;
    }

    &:hover {
      color: $blu1;
    }
  }

  &__submit-container {
    padding: 30px 10px;
  }

  &__submit {
    width: 100%;
    padding: 10px;
    color: $w0;
    font-size: calculateRem(14);
    font-weight: 700;
    background: $blu2;
    border-radius: 3px;
    transition: background 120ms ease;

    &:hover {
      background: darken($blu2, 4);
      transition: background 180ms ease;
    }
  }
}

.brand-logo {
  width: 150px;
  margin: 30px 0px 25px;
}

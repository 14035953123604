﻿@import 'reset', 'config';

// Concerns:
// - Base Typography
// - Global shared styles

body {
  color: $bodyTextColor;
  font-family: $fontFamily;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  background: $bodyBg;
  text-rendering: optimizeSpeed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontHeading;
}

a {
  text-decoration: none;
  color: #0099cc;

  &:hover {
    color: #002946;
  }
}

input,
select,
button {
  font-family: $fontFamily;
}

.react-grid-item.react-grid-placeholder {
  background-color: #0099cc !important;
}

th.k-header.active > div > div {
  color: red;
}

th.k-header.wrapHeader .k-link {
  overflow-wrap: break-word;
  white-space: normal;
}

.tox-tinymce-aux {
  z-index: 13000 !important;
}

.k-tilelayout-item-body {
  cursor: auto;
}

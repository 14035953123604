@keyframes slideDown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@mixin slideDown(
  $duration: 200ms,
  $timing: ease,
  $delay: 0ms,
  $fill: forwards
) {
  transform-origin: top;
  animation: slideDown $duration $timing $delay $fill;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@mixin slideFromLeft(
  $duration: 500ms,
  $timing: ease-out,
  $delay: 0ms,
  $fill: forwards
) {
  animation: slideFromLeft $duration $timing $delay $fill;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@mixin slideFromRight(
  $duration: 500ms,
  $timing: ease-out,
  $delay: 0ms,
  $fill: forwards
) {
  animation: slideFromRight $duration $timing $delay $fill;
}

﻿html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  font-size: 100%;
  font-weight: inherit;
  vertical-align: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

ul {
  list-style: none;
}

input,
select {
  vertical-align: middle;
}

button,
input[type='button'],
input[type='submit'] {
  cursor: pointer;
}

﻿.navigation-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  // background: rgba(250, 250, 250, 0.9);
  background: #002946;
  z-index: 2;

  @keyframes nmenu {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  &--minimize {
    transform: translateX(-100%);
    animation: 0.2s nmenu ease-out 0.8s both;

    .navigation-menu__item {
      height: calculateRem(40);
      padding: 0;
      padding-left: 4px;
      justify-content: center;
    }

    .navigation-menu__name {
      display: none;
    }
  }

  &__item {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    text-transform: capitalize;
    font-size: calculateRem(14);
    // background: rgba($w0, .9);
    transition: all 1s;

    &--active {
      color: $dmcpurple;
      background: transparent;
    }

    &:hover {
      background: linear-gradient(to bottom, rgba(255, 181, 72, 0.75), #ffcc33);
      color: #002946;

      &:not(.navigation-menu--active) .navigation-menu__name {
        animation: dashboard 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 500ms
          forwards;
      }
    }

    &:visited,
    &:link {
      color: white;
      text-decoration: none;
    }
  }

  @keyframes dashboard {
    0% {
      transform: translateX(300%);
      opacity: 0;
    }

    100% {
      transform: translateX(calc(100% + 20px));
      opacity: 1;
    }
  }

  &__name {
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 18px;
    white-space: nowrap;
    background: $dmcgrey;
    padding: 5px 12px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba(#000, 0.2), 0 4px 8px rgba(#000, 0.1);
    opacity: 0;
    transform: translateX(300%);
    pointer-events: none;
  }

  &__item--active .icon {
    background: $blu2;
  }
}

﻿.recently-viewed {
  $p: 10;

  padding-left: calculateRem($p);
  padding-right: calculateRem($p);
  border-top: 1px solid $sideBarBorderColor;
  background: $rightSidebarBg;
  box-shadow: $rightSidebarBoxShadow;

  &__header {
    padding-top: calculateRem(4);
    padding-bottom: calculateRem(4);
    border-bottom: 1px solid $sideBarBorderColor;
  }

  &__title {
    font-size: calculateRem(12);
    font-weight: 500;
    color: $w4;
  }

  &__container {
    padding-left: calculateRem($p);
    padding-right: calculateRem($p);
  }

  &__item {
    display: block;
    padding-top: calculateRem(calc($p / 2));
    padding-bottom: calculateRem(calc($p / 2));
    color: $b4;
    font-size: calculateRem(14);
    font-weight: 700;

    @include transition {
      color: $blu2;
    }
  }
}

#navsearch {
  margin-left: auto;
}
.navbar {
  display: flex;
  align-items: center;
  background: linear-gradient(28deg, $dmcpurple, lighten($dmcpurple, 10%));
  box-shadow: inset 0 -1px rgba(#000, 0.2), 0 1px rgba(#000, 0.1);

  .navbar-right {
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 40px;

    &__container {
      display: flex;

      .header-user,
      .header-notifications,
      .header-recents {
        display: flex;
        align-items: center;

        button {
          color: rgba(#fff, 0.75);

          :hover {
            color: rgba(#000, 0.8);
          }
        }
      }

      .header-recents,
      .header-notifications {
        margin-right: 10px;
      }
    }
  }
}

.unity-identity {
  display: flex;
  align-items: center;
  padding-left: calculateRem(20);
  padding-right: calculateRem(20);
  font-size: calculateRem(14);
  font-weight: 700;
  letter-spacing: 0.04rem;
  text-transform: uppercase;

  &__name {
    color: rgba(255, 255, 255, 0.85);
  }

  &__brand {
    display: flex;
    align-items: center;
    color: $w3;

    &:before {
      $sz: 16;
      $sp: 8;
      content: '';
      display: inline-block;
      width: calculateRem($sz);
      height: calculateRem($sz);
      margin-right: calculateRem($sp);
      margin-left: calculateRem($sp);
      border-radius: calculateRem($sz * 2);
      border: 5px solid $w3;
    }
  }
}

﻿.notifications-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: calculateRem(10);
  padding-right: calculateRem(10);
  background: darken($navBg, 10);
  box-shadow: inset 1px 0 rgba(#000, 0.2), inset 0 -1px rgba(#000, 0.2),
    0 1px rgba(#000, 0.1);

  &__icon {
    position: relative;
    display: flex;
    align-items: center;

    .icon {
      background: $w0;
    }
  }

  &__count {
    position: absolute;
    top: 0;
    right: 0;
    padding: calculateRem(1) calculateRem(2);
    font-size: calculateRem(10);
    font-weight: 700;
    color: $w0;
    background: $red0;
    border-radius: calculateRem(2);
    box-shadow: inset 0 0 0 1px rgba(#000, 0.1);
    transform: translateY(-30%);
  }
}

.notifications {
  background: $rightSidebarBg;
  box-shadow: $rightSidebarBoxShadow;

  &__container {
    padding: calculateRem(10);
  }
}

.notification {
  padding: calculateRem(10);
  font-size: calculateRem(16);
  background: $w0;
  border-radius: calculateRem(3);
  box-shadow: 0 0 0 1px rgba(#000, 0.1), 0 2px rgba(#000, 0.025);

  &:not(:last-child) {
    margin-bottom: calculateRem(10);
  }
}

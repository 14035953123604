﻿@function calculateRem($px) {
  @return calc(($px / 16) * 1rem);
}

$baseTransition: all 200ms ease;

@mixin caret($marginLeft: 5px, $w: 3px, $h: 5px, $c: inherit) {
  content: '';
  width: 0;
  height: 0;
  margin-left: $marginLeft;
  border-left: $w solid transparent;
  border-right: $w solid transparent;
  border-top: $h solid;
  border-top-color: $c;
}

@mixin transition($s: hover, $t: 200ms, $e: ease) {
  transition: all $t * 1.5 $e;

  &:#{$s} {
    @content;
    transition: all $t $e;
  }
}

@mixin placeholderStyles {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:placeholder-shown {
    @content;
  }
}

@mixin selectionStyles {
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

@mixin clearSelection {
  @include selectionStyles() {
    background: transparent;
  }
}

﻿.nav-search {
  $brd: 3;
  $fieldHeight: calculateRem(34);
  max-width: calculateRem(600);
  display: flex;
  align-items: stretch;
  position: relative;
  justify-content: flex-end;
  padding-right: 25px;

  @include bp($min: calculateRem(600)) {
    min-width: calculateRem(600);
  }

  @media only screen and (min-width: 37.5rem) {
    min-width: 20rem;
  }

  @media only screen and (min-width: 60rem) {
    min-width: 37rem;
  }

  &--show-tray {
    .nav-search__field {
      border-bottom-left-radius: 0;
    }

    .nav-search__action {
      border-bottom-right-radius: 0;
    }
  }

  &__select-container {
    display: flex;
    padding-right: 0;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: $w0;
    z-index: 1;
  }

  &__select {
    color: $blu1;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 4px;
    background: rgba($blu2, 0.1);
    box-shadow: inset 0 0 0 1px rgba(#000, 0.1);
    transition: all 100ms ease;
    cursor: pointer;

    &:hover {
      background: rgba($blu2, 0.2);
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 1px $blu2, 0 0 8px $blu2;
    }
  }

  &__field {
    height: $fieldHeight;
    flex: 1;
    max-width: calculateRem(420);
    padding-left: calculateRem(10);
    font-size: calculateRem(18);
    font-weight: 600;
    color: $b4;
    background: $w0;
    box-shadow: 0 0 0 1px rgba(#000, 0.1);

    @include placeholderStyles {
      font-style: italic;
      font-weight: 400;
      color: rgba($blu1, 0.5);
    }

    @include transition($s: focus) {
      outline: 0;
      box-shadow: 0 0 0 1px rgba(#000, 0.1), 0 0 4px 2px rgba(#fff, 0.2);
    }
  }

  &__field:focus + &__action {
    box-shadow: inset 1px 0 rgba(#000, 0.1), 0 0 0 1px rgba(#000, 0.1),
      0 0 4px 2px rgba(#fff, 0.2);
  }

  &__action {
    $pd: 14;
    padding-left: calculateRem($pd);
    padding-right: calculateRem($pd);
    color: $blu1;
    font-size: calculateRem(14);
    font-weight: 600;
    text-transform: capitalize;
    border-top-right-radius: calculateRem($brd);
    border-bottom-right-radius: calculateRem($brd);
    background: $wb;
    box-shadow: inset 1px 0 rgba(#000, 0.1), 0 0 0 1px rgba(#000, 0.1);

    @include transition {
      background: lighten($wb, 3);
      color: lighten($blu1, 3);
    }
  }

  &__tray {
    display: block;
    padding: 60px 20px;
    position: absolute;
    left: 0;
    right: 0;
    background: $w0;
    border-bottom-right-radius: calculateRem($brd);
    border-bottom-left-radius: calculateRem($brd);
    transform: translateY($fieldHeight);
    z-index: 100;
    box-shadow: 0 0 1px rgba(#000, 0.3), 0 4px 10px rgba(#000, 0.1);
  }
}

﻿.container {
  $colW: calculateRem(260);
  $colMinW: calculateRem(55);

  &--minimize-left {
    grid-template-columns: $colMinW auto $colW;
  }

  &--minimize-right {
    grid-template-columns: $colW auto $colMinW;
  }

  &.container--minimize-right.container--minimize-left,
  &--minimize-both {
    grid-template-columns: $colMinW auto $colMinW;
  }

  &--hide-right {
    grid-template-columns: $colW auto $colMinW;
    grid-template-areas:
      'header header right-sidebar-header'
      'sidebar-left-top main main'
      'sidebar-left-bottom main main';
  }

  &--hide-left {
    grid-template-columns: auto $colW;
    grid-template-areas:
      'header right-sidebar-header'
      'main sidebar-right-top'
      'main sidebar-right-bottom';
  }

  &.container--hide-right.container--hide-left,
  &--hide-both {
    grid-template-columns: auto $colMinW;
    grid-template-areas:
      'header right-sidebar-header'
      'main main'
      'main main';
  }

  &.container--hide-right.container--minimize-left {
    grid-template-columns: $colMinW auto auto;
    grid-template-areas:
      'header header right-sidebar-header'
      'sidebar-left-top main main'
      'sidebar-left-bottom main main';
  }
}

.navbar {
  grid-area: header;
}

.navigation-menu {
  grid-area: sidebar-left-top;
}

.current-user {
  grid-area: sidebar-left-bottom;
}

.main-view {
  position: relative;
  grid-area: main;
  z-index: 1;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
}

.navbar-right {
  grid-area: right-sidebar-header;
}

.k-calendar {
  .k-content {
    .k-today {
      color: #333;
    }
  }
  .k-calendar-header {
    .k-today {
      color: #002946;
      &:hover {
        color: #0099cc;
      }
    }
  }
  .k-state-selected {
    .k-link {
      border-color: #0099cc;
      color: white;
      background-color: #0099cc;
    }
  }
  .k-calendar-monthview {
    td {
      width: auto;
      height: auto;
    }
  }
}

.k-animation-container {
  z-index: 12000;
}

.k-dialog-wrapper {
  z-index: 10013;
}

@mixin buttonprimary {
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  line-height: 1;
  transition: all 500ms;
  font-family: sans-serif;
}

.button--primary-navigation-small {
  @include buttonprimary();
  background: #ddd;
  color: #333;

  &:hover {
    background: #ccc;
  }
}

.button--primary-small {
  @include buttonprimary();
  background: #0099cc;
  color: white;

  &:hover {
    background: #0099cc65;
    color: #002946;
  }

  &--next {
    @include buttonprimary();
    background: #0099cc;
    color: white;
    margin-left: auto;
    display: inline-block;
    position: absolute;
    right: 0;
  }

  &--back {
    @include buttonprimary();
    color: #333;
    display: inline-block;
    background: #eee;
  }
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background: #0099cc25;
}

.k-checkbox:checked + .k-checkbox-label::before {
  background-color: #0099cc;
  border-color: #0099cc;
}

.button--primary {
  margin-top: 12px;
  margin-bottom: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #0099cc;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  transition: 500ms all;
  &:hover {
    background: #0099cc65;
  }
  &:disabled {
    background-color: #cccccc;
  }
}

﻿.current-user {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: calculateRem(14);
  background: rgba(250, 250, 250, 0.9);

  &--minimize {
    .current-user__company,
    .current-user__container {
      display: none;
    }
  }

  &__company {
    display: none;
    padding-bottom: calculateRem(10);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.04rem;
    color: rgba(#000, 0.1);
  }

  $pd: 10;
  &__container {
    // display: flex;
    display: none;
    flex-direction: column;
    position: relative;
    padding: calculateRem($pd);
    font-size: calculateRem(14);
    box-shadow: 0 0 0 1px rgba(#000, 0.1);
    background: rgba($w0, 0.9);
    border-radius: calculateRem(3);
  }

  &__name {
    font-weight: 700;
  }

  &__department,
  &__login {
    color: $blu1;
  }

  &__login-date {
    font-weight: bolder;
  }

  &__admin-flag {
    position: absolute;
    top: calculateRem($pd);
    right: calculateRem($pd);
    padding: calculateRem(2) calculateRem(4);
    color: $gre0;
    font-size: calculateRem(9);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    border: 1px solid;
    border-radius: 3px;
    box-shadow: 0 1px rgba(#000, 0.05);
  }

  .button {
    margin-top: 10px;
    margin-left: auto;
  }
}

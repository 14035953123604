﻿@import '../config';

@mixin btn(
  $h: calculateRem(30),
  $p: calculateRem(10),
  $fz: calculateRem(14),
  $c: $b4,
  $fw: 700,
  $lh: 1,
  $bg: $w0,
  $br: 4px,
  $Hc: $blu3,
  $Ac: $w0,
  $Abg: $gre0
) {
  display: inline-flex;
  align-items: center;
  height: $h;
  padding-right: $p;
  padding-left: $p;
  font-size: $fz;
  color: $c;
  font-weight: $fw;
  line-height: $lh;
  background: $bg;
  border-radius: $br;
  box-shadow: 0 0 0 1px rgba(#000, 0.1), inset 0 1px rgba(#fff, 0.2),
    0 2px rgba(#000, 0.025);
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }

  @include selectionStyles {
    background: transparent;
  }

  &:not(#{&--action}) {
    @include transition {
      color: $Hc;
      background: darken($bg, 3);
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 1px $blu2, 0 0 8px $blu2;
      transition: all 200ms ease;
    }
  }

  &--action {
    color: $Ac;
    background: $Abg;
    box-shadow: inset 0 0 0 1px rgba(#000, 0.1), inset 0 2px rgba(#fff, 0.2),
      0 2px rgba(#000, 0.025);

    @include transition {
      color: $Ac;
      background: lighten($Abg, 4);
    }

    &:focus {
      outline: 0;
      box-shadow: inset 0 0 0 1px rgba(#000, 0.2), inset 0 2px rgba(#fff, 0.3),
        0 0 8px 2px rgba($Abg, 0.9);
    }

    .icon {
      background: $Ac;
    }
  }

  &--small {
    height: 24px;
    font-size: calculateRem(12);
    letter-spacing: calculateRem(0.25);

    .icon {
      width: calculateRem(12);
      height: calculateRem(12);
      margin-right: calculateRem(6);
    }
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.button {
  @include btn;
}

﻿@import 'breakpoints', 'utils', 'animations';

// Theme
$w0: #fafafa;
$w3: #c8ccd4;
$w4: #abb2bf;
$w4b: #cccccc;
$wb: #e8ecf1;
$b3: #565c64;
$b4: #2e3c43;
$b44: #263238;
$blu1: #8796b0;
$blu2: #82aaff;
$blu3: #6182b8;
$blugre: #00848e;
$gre0: #98c379;
$gre1: #91b859;
$gra1: #707070;
$red0: #e06c75;
$red1: #bf0711;
$deepRed: darken(#f00, 20);
$prp0: #c792ea;
$dmcpurple: #454e68;
$dmcgrey: #6e6f72;
$dmcred: #d14e52;

// Base Settings
$fontFamily: 'Nunito', sans-serif;
$fontHeading: 'Nunito', sans-serif;
$baseFontSize: 14px;
$baseLineHeight: 1.5;
$bodyTextColor: $b4;

// Color Settings
$bodyBg: #eaeaea;
$navBg: $red1;
$rightSidebarBg: rgba(#000, 0.05);

$sideBarBorderColor: rgba(#000, 0.1);
$leftSidebarBoxShadow: inset -1px 0 $sideBarBorderColor;
$rightSidebarBoxShadow: inset 1px 0 $sideBarBorderColor;
